import * as React from 'react';
import { render } from 'react-dom';

import classnames from 'classnames';
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import { TSDI } from 'tsdi';
import { configure as mobxConfigure } from 'mobx';
import { App } from './app';
import 'mobx-wrap-request';
import { ErrorHandler } from './components/error-handler';

import './styles/reset.css';
import './styles/main.scss';
import { BugsnagErrorBoundary } from './core/bugsnag';
import { TsdiLifeCycle } from './core/reactions';
import { DateTimeStore } from './core/datetime-store';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).classnames = classnames;

smoothscroll.polyfill();

mobxConfigure({
    enforceActions: 'never'
});

const tsdi = new TSDI();
tsdi.enableComponentScanner();
tsdi.get(TsdiLifeCycle);
tsdi.get(DateTimeStore);

const browserLanguage = window.navigator.language.split('-')[0];
document
    .querySelector('html')
    ?.setAttribute('data-user-language', browserLanguage);

render(
    <BugsnagErrorBoundary FallbackComponent={ErrorHandler}>
        <App />
    </BugsnagErrorBoundary>,
    document.getElementById('app')
);
