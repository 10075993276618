import {
    isObservableObject,
    makeAutoObservable,
    observable,
    reaction
} from 'mobx';
import { component, initialize } from 'tsdi';
import {
    isLocalDateOrZonedDateTime,
    LocalDate,
    ZonedDateTime
} from '@spa-frontend/date-lib';
import { injectTSDI } from './tsdi';
import { LocaleStore } from './i18n-store';
import { StudioStore } from './studio-store';

interface MonthDay {
    day: number;
    month: number;
}

const fromMonthDay = (monthDay?: string): MonthDay | undefined => {
    if (!monthDay) {
        return undefined;
    }

    const matches = /--(\d{2})-(\d{2})/.exec(monthDay);

    const month = matches?.[1];
    const day = matches?.[2];

    if (!month || !day) {
        return undefined;
    }

    return {
        month: parseInt(month, 10),
        day: parseInt(day, 10)
    };
};

@component
export class DateTimeStore {
    private intlDay2DigitMonth2Digit!: Intl.DateTimeFormat;

    private get localeStore() {
        return injectTSDI(LocaleStore);
    }

    @initialize
    protected init(): void {
        if (!isObservableObject(this)) {
            makeAutoObservable<typeof this, 'intlDay2DigitMonth2Digit'>(this, {
                intlDay2DigitMonth2Digit: observable.ref
            });
        }

        reaction(
            () => this.localeStore.currentLocale,
            (currentLocale) => {
                if (!currentLocale) {
                    return;
                }

                this.intlDay2DigitMonth2Digit = new Intl.DateTimeFormat(
                    currentLocale,
                    {
                        day: '2-digit',
                        month: '2-digit'
                    }
                );
            },
            { fireImmediately: true }
        );
    }

    public today = () => {
        const { studioTimezone } = injectTSDI(StudioStore);
        return LocalDate.now(studioTimezone);
    };

    private formatDay2DigitMonth2Digit = (
        date: Date | ZonedDateTime | LocalDate
    ) =>
        this.intlDay2DigitMonth2Digit.format(
            isLocalDateOrZonedDateTime(date) ? date.toDate() : date
        );

    public formatMonthDay = (monthDay?: string) => {
        const monthDayParsed = fromMonthDay(monthDay);
        if (!monthDayParsed) {
            return '-';
        }

        const date = new Date(
            2000,
            monthDayParsed.month - 1,
            monthDayParsed.day
        );
        return this.formatDay2DigitMonth2Digit(date);
    };
}
